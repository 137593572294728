.game-container {
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    position: relative;
}

.modal {
    position: fixed;
    z-index: 300;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #cccccc;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    color: black;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.progress-bar-container {
    width: 100%;
    padding: 0.5px;
}

.progress-bar {
    height: 5px;
    width: 100%;
    border: 1px solid #000000;
}

.progress-bar-fill {
    height: 100%;
    text-align: right;
    transition: width 0.3s ease-in-out;
}

.chatbox-container {
    position: fixed;
    bottom: 0;
    width: 50%;
    background-color: #f0f0f0;
    padding: 3px;
    box-sizing: border-box;
    transition: width 0.3s ease;
}

.button-animate {
    transition: background-color 0.3s ease;
    background-color: black;
}

.button-animate img {
    transition: transform 0.3s ease;
}

.button-animate:active img {
    transform: scale(0.9);
}

.button-animate:hover {
    background-color: #484848;
}

@keyframes fadeAway {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.item-description {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    z-index: 10;
    width: 200px;
    top: 400px;
    left: 500px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.equipment-description {
    position: absolute;
    background-color: rgb(234, 234, 234);
    border: 1px solid black;
    padding: 5px;
    z-index: 10;
    width: 365px;
    height: 300px;
    top: 400px;
    left: 500px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.card-description-modal-content {
    max-height: 400px;
    overflow-y: auto;
}

.description-close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}

@media (max-width: 600px) {
    .progress-bar-container,
    .progress-bar {
        width: 100%;
    }
    .chatbox-container {
        width: 100%;
    }
    .item-description {
        top: 50px;
        left: 100px;
    }
    .equipment-description {
        top: 100px;
        left: 0px;
    }
}

.equipment-section {
    display: flex;
    justify-content: space-between;
}

.equipment-item {
    flex: 1;
    padding: 10px;
    text-align: center;
}

.equipment-item div {
    text-align: center;
}

.equipment-item img {
    margin-bottom: 10px;
}

.inventory-section {
    max-height: 300px;
    overflow-y: auto;
}

.inventory-section li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.inventory-section img {
    margin-right: 10px;
}

.directional-button:disabled {
    background-color: black !important;
    cursor: not-allowed;
}

.version-modal-content {
    max-height: 400px;
    overflow-y: auto;
}

.cards-modal-content {
    max-height: 400px;
    overflow-y: auto;
}

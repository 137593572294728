.localCouchBattler {
  padding: 5px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.gameHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.gameHeader button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.gameHeader button:hover {
  background-color: #45a049;
}

.gameBoards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
}

.playerBoard {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 350px;
  overflow: auto;
}

.playerBoard:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

.playerBoard:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}

.playerBoard:nth-child(3) {
  grid-column: 1;
  grid-row: 2;
}

.playerBoard:nth-child(4) {
  grid-column: 2;
  grid-row: 2;
}

.playerBoard h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
}

.classSelect h3 {
  margin-top: 0;
  color: #d28a15;
  font-size: 16px;
}

.classOptions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.classOption {
  flex: 1;
  padding: 12px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
  font-size: 14px;
}

.classOption.selected {
  background-color: #4caf50;
  color: white;
  border-color: #45a049;
  transform: translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  position: relative;
}

.classOption.selected::after {
  content: "▼";
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  color: #4caf50;
  font-size: 16px;
}

.playerStats {
  background-color: #fff;
  color: black;
  padding: 12px;
  border-radius: 4px;
  overflow: auto;
}

.playerStats h3 {
  margin-top: 0;
  color: #333;
  text-align: center;
  margin-bottom: 12px;
  font-size: 16px;
}

.statsDisplay {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 15px;
}

.statsDisplay div {
  padding: 6px;
  background-color: #f8f8f8;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

.playerActions {
  margin-top: 15px;
}

.playerActions h4 {
  color: #555;
  margin-bottom: 8px;
  font-size: 15px;
}

.actionOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actionOption {
  padding: 10px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
  font-size: 14px;
}

.actionOption.selected {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
  transform: scale(1.03);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: relative;
}

.actionOption.selected::before {
  content: "►";
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
}

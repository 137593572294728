.cam-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 1vh;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

.cam-body {
  margin-top: calc(2vh + 2rem);
}

.cam-world {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
}

.cam-world-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 2vh;
  z-index: 1000;
}

.cam-decks {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
}

.cam-decks-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 2vh;
  z-index: 1000;
}

.cam-deck-edit {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
}

.cam-deck-edit-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 2vh;
  z-index: 1000;
}

.cam-battle {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.cam-battle-results {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
}

.battle-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.entity-queue {
  height: 5%;
  border: 1px solid black;
  white-space: nowrap;
  overflow-x: auto;
}

.enemy-area,
.player-area {
  height: 20%;
  border: 1px solid black;
}

.player-hand {
  height: 55%;
  display: flex;
  border: 1px solid black;
}

.player-info {
  width: 15%;
}

.battle-results-info {
  margin-bottom: 10px;
}

.hand-cards {
  width: 85%;
  border-left: 1px solid black;
  position: relative;
}

.end-turn-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #ce0688;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.end-turn-button:hover {
  background-color: #940043;
}

.cam-battle-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 1vh;
  z-index: 1000;
}

.cam-collection {
  margin-top: calc(2vh + 2rem);
  margin-bottom: calc(2vh + 2rem);
  padding: 2vh;
}

.cam-collection-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(200, 116, 0);
  padding: 2vh;
  z-index: 1000;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

.card-button {
  width: 100%;
  height: 100px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: hidden;
  padding: 5px;
}

.card-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.card-button:hover {
  background-color: #e0e0e0;
}

.hand-card-button.selected {
  background-color: #ffd580;
}

.hand-card-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.hand-card-button.energy-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.turn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.enemy-info.selectable,
.entity-info.selectable {
  margin-bottom: 5px;
}

.enemy-button,
.player-button {
  padding: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.enemy-button.selected,
.player-button.selected {
  background-color: #ffd580;
}

.player-stats-text {
  font-size: xx-small;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  color: black;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

:root {
  --faction-forestkin-color: #608031;
  --faction-forestkin-color-light: #759b3b;
  --faction-forestkin-color-dark: #344b1f;
  --faction-chaos-color: #4f2167;
  --faction-chaos-color-light: #6d3b8e;
  --faction-chaos-color-dark: #2a1635;
  --faction-machinists-color: #004980;
  --faction-machinists-color-light: #0077b6;
  --faction-machinists-color-dark: #00273c;
  --faction-arcanists-color: #a99b00;
  --faction-arcanists-color-light: #dbc900;
  --faction-arcanists-color-dark: #675a00;
}

.unnamed-card {
  position: relative;
  z-index: 1000;
  width: 300px;
  height: 500px;
  border: 5px solid #5a461f;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Card.css */
.unnamed-card-image-forestkin {
  background-color: var(--faction-forestkin-color);
}

.unnamed-card-image-chaos {
  background-color: var(--faction-chaos-color);
}

.unnamed-card-image-machinists {
  background-color: var(--faction-machinists-color);
}

.unnamed-card-image-arcanists {
  background-color: var(--faction-arcanists-color);
}

.unnamed-card-image {
  width: 100%;
  height: 100%;
  border-bottom: 5px solid #26241f;
}

.unnamed-card-stats-forestkin {
  background-color: var(--faction-forestkin-color-light);
}

.unnamed-card-stats-chaos {
  background-color: var(--faction-chaos-color-light);
}

.unnamed-card-stats-machinists {
  background-color: var(--faction-machinists-color-light);
}

.unnamed-card-stats-arcanists {
  background-color: var(--faction-arcanists-color-light);
}

.unnamed-card-stats {
  height: 35%;
}

.unnamed-card-content-forestkin {
  background-color: var(--faction-forestkin-color-dark);
}

.unnamed-card-content-chaos {
  background-color: var(--faction-chaos-color-dark);
}

.unnamed-card-content-machinists {
  background-color: var(--faction-machinists-color-dark);
}

.unnamed-card-content-arcanists {
  background-color: var(--faction-arcanists-color-dark);
}

.unnamed-card-content {
  margin-top: -10px;
  padding: 10px;
  height: 65%;
}

.unnamed-card-name {
  font-size: 35px;
  color: #ddd9d2;
}

.unnamed-card-description {
  font-size: 20px;
  color: #dec698;
}

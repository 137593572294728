* {
  font-family: "Noto Sans Mono", "Roboto", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  margin: 0px;
  background: #194963;
  color: #f8f8f8;
}

a {
  color: #ffaa00;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-color: #2f2f2f;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  background-color: #232323;
  color: #f8f8f8;
  font-size: 1.1rem;
  outline: 0;
  cursor: pointer;
}

.btn_sizeMin {
  min-height: 50px;
  min-width: 100px;
}

.btn_sizeSm {
  padding: 10px 15px;
  font-size: 0.8rem;
}

.btn_sizeFull {
  display: block;
  width: 100%;
}

.btn:hover {
  background-color: #292929;
  text-decoration: none;
}

/* This line makes the "Register" and "Login" buttons look like links */
.btn_asLink {
  padding: 0;
  border-width: 0;
  border-bottom: 1px solid #444;
  border-radius: 0;
  background: none;
  font: inherit;
  color: #ffffff;
}

.btn_asLink:hover {
  background-color: transparent;
  border-color: #202020;
  color: #202020;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------------
Form Treatment
-------------------------------------------------------------------------------- */

.formPanel {
  box-sizing: border-box;
  width: 400px;
  max-width: 100%;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: #00152a;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
}

.container {
  display: flex;
}

.skillsSidebar {
  width: 200px;
}

.skillButton {
  display: block;
  margin-bottom: 10px;
}

.mainContent {
  margin-left: 20px;
}

.main {
  margin-bottom: 20px;
}

.gameContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.topBar {
  height: 50px;
  background-color: #222;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.actionName {
  margin-right: 16px;
}

.actionProgress {
  flex: 1;
  background-color: #555;
  height: 15px;
  position: relative;
}

.actionProgressFill {
  background-color: #4caf50;
  height: 15px;
  transition: width 0.2s;
}

.mainArea {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.sidebarLeft {
  background-color: #333;
  color: #fff;
  overflow-y: auto;
}

.centerContent {
  flex: 1;
  background-color: #111;
  color: #fff;
  overflow-y: auto;
  padding: 16px;
}

.sidebarRight {
  width: 250px;
  background-color: #333;
  color: #fff;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.rightTabs {
  display: flex;
  border-bottom: 1px solid #444;
  margin-bottom: 8px;
}

.rightTab {
  flex: 1;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  background-color: #444;
  margin-right: 4px;
}

.rightTab:last-of-type {
  margin-right: 0;
}

.activeTab {
  background-color: #666;
}

.rightTabContent {
  padding: 8px;
}

.bottomChat {
  height: 120px;
  background-color: #222;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.chatInput {
  display: flex;
  border-top: 1px solid #444;
}

.chatInput input {
  flex: 1;
  padding: 8px;
  border: none;
  outline: none;
  background-color: #333;
  color: #fff;
}

.skillContainer {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #444;
}

.skillContainer:hover {
  background-color: #555;
}

.skillNameAndLevel {
  display: flex;
  justify-content: space-between;
}

.xpBar {
  margin-top: 5px;
  width: 100%;
  height: 5px;
  background-color: #444;
  position: relative;
}

.xpFill {
  height: 5px;
  background-color: #4caf50;
  transition: width 0.2s;
}

.tooltipContainer {
  position: relative;
  display: block;
}

.tooltipText {
  visibility: hidden;
  position: absolute;
  background-color: #878787;
  color: #fff;
  border: 1px solid #444;
  padding: 4px 6px;
  border-radius: 4px;
  left: 0%;
  top: -50%;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 9999;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
}

.selectedSkill {
  background-color: #0f0f0f;
  box-shadow: 0 0 6px #00faff, 0 0 12px #00faff;
  color: #00faff;
}

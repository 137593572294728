.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
}

.cardContainer {
  position: relative;
  width: 150px;
  height: 220px;
  border: 2px solid #fff;
  border-radius: 8px;
  background-color: #000;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform: scale(2.5);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  animation: fadeOutDefault 3s forwards;
}

.manualFade {
  animation: fadeOutQuick 0.4s forwards;
}

.turnIndicatorContainer {
  width: 200px;
  height: 80px;
  background-color: rgba(156, 78, 0, 0.9);
  border-radius: 10px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeOutDefault 3s forwards;
  pointer-events: none;
  position: relative;
  transform: scale(2);
}

.turnIndicatorText {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
}

@keyframes fadeOutDefault {
  0% {
    opacity: 1;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutQuick {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cardTitle {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

.factionIcon {
  position: absolute;
  top: 24px;
  left: 4px;
  width: 20px;
  height: 20px;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.statsRow {
  position: absolute;
  bottom: 28px;
  display: flex;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 2px 4px;
}

.stat {
  font-size: 0.7rem;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.effectText {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  font-size: 0.6rem;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.playerLabel {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 0.6rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 1px 4px;
}

/* In TreasureHunter.css */

.focused {
  background-color: #007bff !important;
  color: white;
}

.player-board {
  display: inline-block;
  margin-right: 20px;
}

.cell {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 2px;
}

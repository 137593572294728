.foodAndExercise {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #000;
  background-color: #fff8e7;

  h1 {
    margin-bottom: 1.5rem;
    text-align: center;
    color: #000;
  }

  .loginPrompt {
    text-align: center;
    padding: 2rem;
    background-color: #faf0e6;
    border-radius: 8px;
    margin-top: 2rem;
    color: #000;
  }

  .addEntrySection {
    background-color: #faf0e6;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    color: #000;

    h2 {
      margin-bottom: 1rem;
      color: #000;
    }

    .typeSelector {
      display: flex;
      margin-bottom: 20px;

      button {
        flex: 1;
        padding: 10px;
        background: #f0e6d2;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #000;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &.active {
          background: #c19a6b;
          color: white;
        }
      }
    }

    form {
      .formGroup {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
          color: #000;
        }

        input,
        .react-datepicker-wrapper {
          width: 100%;
          padding: 8px;
          border: 1px solid #d9c9b6;
          border-radius: 4px;
          font-size: 1rem;
          color: #000;
          background-color: #fff;

          &:focus {
            outline: none;
            border-color: #c19a6b;
            box-shadow: 0 0 0 0.2rem rgba(193, 154, 107, 0.25);
          }
        }
      }

      button {
        width: 100%;
        padding: 10px;
        background-color: #8b7355;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #6b5839;
        }
      }
    }
  }

  .entriesByWeek {
    h2 {
      margin-bottom: 1rem;
      color: #000;
    }

    .weekEntry {
      background-color: #faf0e6;
      border-radius: 8px;
      margin-bottom: 15px;
      overflow: hidden;
      color: #000;

      .weekHeader {
        padding: 12px 15px;
        background-color: #f0e6d2;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #e6d2b5;
        }

        .weekHeaderContent {
          display: flex;
          align-items: center;

          h3 {
            margin: 0;
            font-size: 1.1rem;
            cursor: pointer;
            flex-grow: 1;
            color: #000;
          }
        }
      }

      .toggleButton {
        background: none;
        border: none;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
        min-width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 8px;
        padding: 0;
        border-radius: 50%;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.weekToggle {
          color: #8b7355;
        }

        &.dayToggle {
          color: #8b7355;
          font-size: 1.25rem;
        }
      }

      .weekContent {
        padding: 0 15px;
        transition: max-height 0.3s ease;
      }

      .dayEntry {
        padding: 12px 0;
        border-bottom: 1px solid #e6d2b5;

        &:last-child {
          border-bottom: none;
        }

        .dayHeader {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          width: 100%;
          flex-wrap: nowrap;

          h4 {
            margin: 0;
            color: #000;
            font-size: 1rem;
            cursor: pointer;
            flex-grow: 1;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .dayContent {
          padding-left: 30px;
        }

        .foodEntries,
        .exerciseEntries {
          margin-bottom: 15px;

          h5 {
            margin-bottom: 8px;
            font-size: 0.95rem;
            font-weight: bold;
            color: #000;
          }

          ul {
            list-style-type: none;
            padding-left: 0;
            margin-left: 10px;

            li {
              padding: 5px 0;
              border-bottom: 1px solid #e6d2b5;
              color: #000;

              &:last-child {
                border-bottom: none;
              }

              .entryContent {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .entryName {
                  flex-grow: 1;
                  color: #000;
                }

                .deleteButton {
                  background: none;
                  border: none;
                  color: #8b4513;
                  font-size: 1.5rem;
                  font-weight: bold;
                  cursor: pointer;
                  padding: 0 8px;
                  line-height: 1;
                  opacity: 0.7;
                  transition: opacity 0.3s ease;

                  &:hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .entriesGraph {
    background-color: #faf0e6;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;

    h2 {
      margin-bottom: 1rem;
      color: #000;
    }

    .chartContainer {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      height: 350px;
      width: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

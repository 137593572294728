.games-landing-page {
  text-align: center;
  padding: 20px;
}

.games-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.game-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.game-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.game-logo:hover {
  box-shadow: 0 0 20px rgba(0, 191, 255, 0.9);
}

@media (max-width: 768px) {
  .games-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.handContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: 200px;
  justify-content: center;
}

.playerName {
  margin: 0;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opponentName {
  margin: 0;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.versusContainer {
  flex-shrink: 0;
  width: 30px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.versus {
  color: #ffd700;
  font-size: 0.95rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  gap: 1rem;
}

.endTurnButton {
  margin-top: 2rem;
}

.resourceIcon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardActionOverlay {
  position: absolute;
  bottom: 35%;
  left: 55%;
  transform: translateX(-50%);
  z-index: 1000;
}

.resourcesRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gameContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin-top: -10px;
  position: relative;
}

.gameOverModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
  text-align: center;
  width: 450px;
}

.modalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
  z-index: 9999;
  color: black;
}

.modalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 500px;
  width: 100%;
}

.modalContentStyle {
  max-height: 50vh;
  width: 90vw;
  max-width: 500px;
  overflow-y: auto;
  background: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
  color: black;
}

.closeButton {
  position: absolute;
  top: -20px;
  right: -20px;
  background: black;
  border: none;
  cursor: pointer;
  font-size: 50px;
  color: white;
  padding: 0;
  margin: 0;
  line-height: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.closeButton:hover,
.closeButton:focus {
  transform: scale(1.1);
  outline: 2px solid white;
}

.topMenuButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 0;
  margin: 0;
  list-style: none;
}

.lobbyContainer {
  flex-direction: column;
  padding: 1rem;
}

.lobbyContent {
  display: flex;
  flex: 1;
  gap: 20px;
}

.chatContainer {
  flex: 3;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 20vh;
  max-height: 600px;
  min-height: 300px;
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  min-height: 144px;
}

.playersContainer {
  flex: 1;
  overflow-y: auto;
  height: 20vh;
  max-height: 600px;
  min-height: 290px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 0 0 10px;
}

.chatMessage {
  font-size: 2rem;
}

.chatMeta {
  font-size: 2rem;
}

.chatTime {
  color: gold;
}

.chatUser {
  color: orange;
}

.chatInputContainer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chatInput {
  flex: 1;
  padding: 8px;
  margin-right: 8px;
  font-size: 1rem;
  background-color: #efdecd;
}

.sendButton {
  padding: 8px 16px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.playerList {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.playerItem {
  padding: 3px;
}

.logoImage {
  max-width: 600px;
  height: auto;
  display: block;
  margin: auto;
}

.cardDescription {
  position: absolute;
  bottom: 20px;
  left: 2px;
  right: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 6px 4px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 8px;
  text-align: center;
  width: 90%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.gameHeader {
  position: relative;
  width: 80%;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.deckCount {
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
}

.deckInfoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: -7%;
  top: 50%;
  transform: translateY(-50%);
}

.roundInfoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: -17%;
  top: 50%;
  transform: translateY(-50%);
}

.roundText {
  font-size: 16px;
  color: white;
}

.hexBoardContainer {
  position: relative;
  margin: 1rem auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.endTurnContainer {
  position: relative;
  width: 625px;
  height: 60px;
  margin: 0 auto;
}

.leftGroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.playerResources {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.opponentResources {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

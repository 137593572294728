.cardosis-boards {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: #f3ccab;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.cardosis-hexagon {
  width: 92px;
  height: 106px;
  background-color: #a47b59;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  margin: 1px;
  outline: none;
  border: none;
  transition: background-color 0.3s;
  position: relative;
}

.cardosis-hexagon-row {
  display: flex;
  align-items: center;
  margin-bottom: -26px;
}

.cardosis-hexagon-row-offset {
  margin-left: 93px;
}

.cardosis-player-board {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardosis-enemy-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scaleX(-1);
}

.cardosis-grey-square {
  width: 65px;
  height: 65px;
  background-color: #9b9b9b;
  margin-right: 50px;
  border: none;
  padding: 0;
  position: absolute;
}

.cardosis-card-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin-top: -100px;
  overflow-x: auto;
  overflow: visible;
}

.cardosis-card {
  position: relative;
  z-index: 1000;
  width: 300px; /* Adjust as needed */
  min-width: 300px; /* Minimum width to prevent squishing */
  height: 450px;
  border: 5px solid #030303;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #719822;
  margin-left: -100px; /* Overlap the cards by 50 pixels */
}

.cardosis-card-active {
  margin-top: -50px;
}

.cardosis-card-image {
  width: 100%;
  background-color: #719822;
}

.cardosis-card-content {
  margin-top: -10px;
  padding: 10px;
  background-color: rgb(178, 141, 61);
}

.cardosis-card-name {
  font-size: 25px;
}

.cardosis-card-description {
  height: 200px;
  font-size: 14px;
  color: #000000;
}

.monsterIdleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerPanel {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.gamePanel {
  display: flex;
  width: 100%;
  flex: 1;
}

.navPanelExpanded {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.navPanelCollapsed {
  display: flex;
  flex-direction: column;
  width: 50px;
}

.toggleButton {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.navItem {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.navItemSelected {
  background-color: #ddd;
}

.contentPanel {
  flex: 1;
  padding: 16px;
}

.playerInventory {
  width: 300px;
  padding: 16px;
}

@media (max-width: 768px) {
  .playerInventory {
    display: none;
  }
  .navPanelExpanded {
    width: 150px;
  }
  .navPanelCollapsed {
    width: 40px;
  }
}

.player-board-container {
  display: inline-block;
  text-align: center;
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.player-board {
  display: grid;
  gap: 5px;
  grid-auto-rows: minmax(80px, auto);
}

.cell {
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.cell:hover {
  background-color: #333;
}

.roomContainer {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  min-height: 200px;
  cursor: pointer;
  position: relative;
}

.roomContainer:hover {
  box-shadow: inset 0 0 0 4px gold;
}

.roomGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.creatureBox {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  background-color: black;
}

.creatureBox.selected {
  box-shadow: inset 0 0 0 2px yellow;
}

.creatureBox.smallBox {
  margin: 0;
  width: 45px;
  height: 45px;
}

.arenaMatchContainer {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px;
  margin-bottom: 6px;
  border-radius: 5px;
}

.itemStats {
  position: absolute;
  bottom: -50px;
  right: 0;
  transform: translate(100%, 0);
  background-color: black;
  color: white;
  padding: 5px;
  width: 180px;
  font-size: 12px;
  z-index: 1;
  text-align: left;
}

.buyRandomButton {
  position: relative;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.buyRandomButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
  transition: background-color 0.3s;
}

.buyRandomButton:hover::before {
  background-color: #007bff;
}

.buyRandomButton:hover {
  transform: scale(1.1);
  z-index: 1;
}

.creatureStats {
  position: absolute;
  top: 60px;
  left: 0;
  background-color: black;
  width: 250px;
  color: white;
  border: 1px solid #fff;
  border-radius: 4px;
  z-index: 1;
  font-size: 12px;
  transition: max-height 0.3s ease;
}

.creatureStatsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
}

.toggleButton {
  width: 20px;
  height: 20px;
  background: none;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
}

.creatureStatsContent {
  margin-top: 6px;
  padding: 6px;
}

.creatureStats::-webkit-scrollbar {
  width: 6px;
}

.creatureStats::-webkit-scrollbar-track {
  background-color: #222;
}

.creatureStats::-webkit-scrollbar-thumb {
  background-color: #555;
}

.attackFlash {
  animation: attackGlow 4s forwards;
}

@keyframes attackGlow {
  0% {
    box-shadow: 0 0 8px 4px rgba(255, 0, 0, 1);
  }
  100% {
    box-shadow: 0 0 8px 4px rgba(255, 0, 0, 0);
  }
}

.separator {
  margin: 0 10px;
}

.healthBar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #444;
}

.healthBarFill {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: red;
}

.manaBar {
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #444;
}

.manaBarFill {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: blue;
}

.creatureLevel {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  padding: 1px 2px;
  font-size: 12px;
}

.questBox {
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 6px;
  display: inline-block;
}

.questBoxContent {
  display: flex;
  align-items: flex-end;
}

.verticalProgressBar {
  width: 10px;
  height: 50px;
  background-color: #ccc;
  position: relative;
  margin-right: 5px;
}

.verticalProgressBarMines {
  float: left;
  width: 10px;
  height: 50px;
  background-color: #ccc;
  position: relative;
  margin-right: 8px;
}

.verticalProgressFillGreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: green;
}

.verticalProgressFillGold {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: gold;
}

.progressAssignBox {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px;
  margin: 5px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.assignBoxHeader {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.assignBoxCreatures {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  max-height: none;
  overflow: visible;
}

.itemContainer {
  position: relative;
  text-align: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectedItem {
  border: 2px solid yellow;
}

.itemLevelLabel {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 10px;
}

.creatureDisplay {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.explorationZoneContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

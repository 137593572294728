:root {
  --faction-forestkin-color: #608031;
  --faction-chaos-color: #4f2167;
  --faction-machinists-color: #004980;
  --faction-arcanists-color: #a99b00;
}

.unnamed-boards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: #afa896;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: 600px;
}

.unnamed-hexagon {
  width: 92px;
  height: 106px;
  background-color: #634518;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  margin: 1px;
  outline: none;
  border: none;
  transition: background-color 0.3s;
  position: relative;
}

.unnamed-hexagon.hexagon-selected {
  background-color: rgba(25, 176, 226, 0.5) !important;
}

.unnamed-hexagon-adjacent {
  background-color: rgb(84, 84, 84);
}

.unnamed-card-token-forestkin {
  background-color: var(--faction-forestkin-color);
}

.unnamed-card-token-chaos {
  background-color: var(--faction-chaos-color);
}

.unnamed-card-token-machinists {
  background-color: var(--faction-machinists-color);
}

.unnamed-card-token-arcanists {
  background-color: var(--faction-arcanists-color);
}

.unnamed-card-token {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unnamed-hexagon:hover {
  background-color: rgb(59, 35, 0);
}

.unnamed-hexagon-row {
  display: flex;
  align-items: center;
  margin-bottom: -26px;
}

.unnamed-hexagon-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.unnamed-hexagon-container.offset {
  margin-left: 93px;
}

.unnamed-flip-horizontal {
  transform: scaleX(-1);
}

.unnamed-grey-square {
  width: 65px;
  height: 65px;
  background-color: #5d4a4a;
  margin-right: 50px;
  border: none;
  border-radius: 5%;
  padding: 0;
  position: absolute;
}

.unnamed-grey-square:hover {
  background-color: #413333;
}

.unnamed-player-board,
.unnamed-enemy-board {
  position: relative;
}

.unnamed-player-board .unnamed-grey-square {
  left: -40px;
}

.unnamed-enemy-board .unnamed-grey-square {
  right: 300px;
}

.unnamed-game-info-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #292929;
  color: #ffecc8;
  border-radius: 10px;
}

.info-display {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Make it span the full width */
  background-color: #f0f0f0; /* Example background color */
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  display: flex; /* Use Flexbox for horizontal layout */
  align-items: center; /* Align items vertically in the center */
  color: black;
}

.info-display ul {
  list-style-type: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
  display: flex; /* Use Flexbox for horizontal layout */
  justify-content: space-between; /* Distribute items evenly across the horizontal axis */
  align-items: center; /* Align items vertically in the center */
}

.info-display li {
  /* Style your list items here */
}

.unnamed-player-name-box,
.unnamed-round-timer-box,
.unnamed-enemy-name-box {
  flex: 1;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.unnamed-player-cards {
  background-color: #201e19;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.unnamed-demo__cards {
  width: 100%;
  max-width: 960px;
}

.unnamed-player-cards .unnamed-cards {
  position: relative;
}

.unnamed-player-cards .unnamed-cards__item {
  position: absolute;
  left: 25px;
  top: 50px;
  width: 10px;
}

.unnamed-cards__item {
  transition: transform 0.1s, box-shadow 0.1s;
}

.unnamed-cards__item:hover {
  transform: translateY(-50px);
  z-index: 1000;
}

@media (orientation: portrait) {
  .unnamed-player-cards {
    position: relative; /* Needed for z-index to take effect */
    z-index: 300;
    height: 200px;
  }

  .unnamed-demo__cards {
    max-width: 250px;
  }

  .unnamed-player-cards .unnamed-cards__item {
    transform: scale(0.3);
    height: 100px;
    top: 0px;
    left: 0px;
  }

  .unnamed-player-cards {
    justify-content: center;
    align-items: center;
  }

  .unnamed-boards-container {
    position: relative; /* Ensure this is also positioned if you need to use z-index here too */
    z-index: 1;
    display: flex;
    height: 200px;
  }

  .info-display {
    width: 100%;
    height: 5%;
    font-size: 0.4em;
  }

  .unnamed-board,
  .unnamed-enemy-board {
    padding: 0; /* Reset paddings */
    transform: scale(0.35); /* Adjust scale to fit the boards */
  }

  .unnamed-player-board {
    margin-right: -130px; /* Reduce right margin for the player board */
  }

  .unnamed-enemy-board {
    margin-left: -130px; /* Reduce left margin for the enemy board */
    transform: scaleX(-1) scale(0.35); /* Apply both flipping and scaling */
  }
}

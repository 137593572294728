.duelCards {
  position: relative;
}

.tooltip {
  position: fixed;
  background: #222;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: 10000;
  transform: translate(-50%, -100%);
  pointer-events: none;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 220px;
  padding: 0;
}

.cardName {
  font-weight: bold;
}

.fullSizeButton {
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.fullSizeButton:hover {
  transform: scale(1.1);
}

.fullSizeButton:active {
  transform: scale(0.9);
}

.imageWrapper {
  position: relative;
  width: 100%;
  flex: 1;
}

.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

.cardOverlay {
  position: absolute;
  top: 1%;
  left: 2.5%;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
}

.overlayName {
  font-weight: bold;
  margin-bottom: 4px;
}

.overlayRow {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.overlayIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
}

.modalImg {
  width: 200px;
  height: auto;
}

.statsContainer {
  margin-top: 16px;
}

.statsSections {
  display: flex;
  gap: 2rem;
}

.effectsSection {
  margin-top: 16px;
}

.gameArea {
  display: flex;
  flex-direction: column;
}

.boards {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.opponentBoard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
}

.playerBoard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
}

.boardSlot {
  box-sizing: border-box;
  width: 20%;
  height: 120px;
  border: 1px solid #ccc;
  background-color: #333;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boardCard {
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
  color: #fff;
  margin: 0;
  border: 1px solid #444;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-sizing: border-box;
}

.boardCardSelected {
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
  color: #fff;
  margin: 0;
  border: 4px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 2;
}

.playerResourcesRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.resourceItem {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.playerHand {
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
  height: 160px;
  position: relative;
}

.handScroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
}

.handScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #222;
}

.handScroll::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.handScroll::-webkit-scrollbar-track {
  background-color: #333;
}

.handCard {
  min-width: 90px;
  height: 150px;
  position: relative;
  background: none;
  color: #fff;
  margin-right: 0;
  z-index: 1;
  cursor: pointer;
  border: 1px solid #444;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.handCardSelected {
  min-width: 86px;
  height: 146px;
  background: none;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  border: 4px solid #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cardTitle {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
  z-index: 1;
  font-size: 0.5rem;
}

.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.handCardOverlay {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 2px;
  text-align: center;
}

.handCardTitle {
  font-size: 0.3rem;
  font-weight: bold;
}

.handCardCost {
  display: flex;
  gap: 4px;
  margin-top: 2px;
  font-size: 0.75rem;
}

.factionIcon {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .factionIcon {
    width: 20px;
    height: 20px;
  }
}

.resourceChoices {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.resourceButton {
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.resourceButton:hover {
  transform: scale(1.1);
}

.resourceButton:active {
  transform: scale(0.9);
}

.resourceButton:disabled {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
}

.cardCostRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.7rem;
  margin: 2px 0;
  width: 100%;
  text-align: left;
}

.cardFactionIcon {
  width: 14px;
  height: auto;
  margin-left: 3px;
}

.playerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}

.statsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.statItem {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  position: relative;
}

.opponentInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.opponentResourcesRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.opponentName {
  font-weight: bold;
}

.cardStats {
  position: absolute;
  bottom: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.playerHand .handCard .cardStats {
  bottom: 2px;
}

.playerHand .handCardSelected .cardStats {
  bottom: 2px;
}

.attackStat,
.defenseStat,
.lifeStat {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attackStat img,
.defenseStat img,
.lifeStat img {
  position: absolute;
  width: 24px;
  height: 24px;
}

.attackStat span,
.defenseStat span,
.lifeStat span {
  position: relative;
  z-index: 1;
  font-size: 0.6rem;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
}

.deckCardSliverContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.deckCardSliver {
  width: 120px;
  height: 20px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.5rem;
  margin-right: 8px;
}

.buttonContainer {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.addCardButton {
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.addCardButton:hover {
  transform: scale(1.1);
}

.addCardButton:active {
  transform: scale(0.9);
}

.damageIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  animation: fade-out 7s forwards;
  transform: translate(-50%, -50%);
}

.damageText {
  color: red;
}

.healText {
  color: green;
}

.playerLifeIndicator,
.opponentLifeIndicator {
  position: absolute;
  top: 0px;
  left: 20px;
  font-size: 2rem;
  font-weight: bold;
  animation: fade-out 7s forwards;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.shopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.stunStat {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stunStat img {
  position: absolute;
  width: 24px;
  height: 24px;
}

.stunStat span {
  position: relative;
  z-index: 1;
  font-size: 0.6rem;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.summonSickStat {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summonSickStat img {
  position: absolute;
  width: 24px;
  height: 24px;
}

.shopRow {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.iconButton {
  color: #fff;
  background: rgb(168, 116, 38);
  border: none;
  cursor: pointer;
  width: 80px;
  height: 120px;
  border-radius: 8px;
  border: 2px solid #ccc;
}
